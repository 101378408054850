<template>
  <div class="panel-body" :ref="bottomRef" style="color: white;">
    <Loader v-if="isPending" />
  </div>
</template>
<script>
import { EventType, VERTICAL_SCROLL_PAGINATOR } from '@/constants'
import {
  getIsElementVisible,
  callFn,
  isFunction,
  isBoolean,
  ifElse,
  unless,
  always,
} from '@/helpers'

const VERTICAL_SCROLL_PAGINATOR_BOTTOM_REF = 'verticalScrollPaginatorBottomRef'

export default {
  name: VERTICAL_SCROLL_PAGINATOR,
  components: {
    Loader: () => import('@/components/Loader'),
  },
  props: {
    isPending: Boolean,
    hasMore: [Boolean, Function],
    loadMore: Function,
  },
  computed: {
    bottomRef: () => VERTICAL_SCROLL_PAGINATOR_BOTTOM_REF,
  },
  methods: {
    handleScroll() {
      const bottom = this.$refs[this.bottomRef]
      const isListBottomVisible = getIsElementVisible(bottom)

      const hasMore = ifElse(
        isFunction,
        callFn,
        unless(isBoolean, always(true)),
      )(this.hasMore)

      isListBottomVisible && hasMore && !this.isPending && callFn(this.loadMore)
    },
  },
  watch: {
    isPending(isPending) {
      !isPending && this.handleScroll()
    },
  },
  mounted() {
    document.addEventListener(EventType.SCROLL, this.handleScroll)
  },
  beforeDestroy() {
    document.removeEventListener(EventType.SCROLL, this.handleScroll)
  },
}
</script>
